/* eslint-disable */
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContactReferralTabQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  contactId: Types.Scalars['ID'];
}>;

export type ContactReferralTabQuery = { __typename?: 'Query' } & {
  contact: { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'> & {
      contactReferralsByMe: { __typename?: 'ReferralConnection' } & {
        nodes: Array<
          { __typename?: 'Referral' } & Pick<
            Types.Referral,
            'id' | 'createdAt'
          > & {
              referredTo: { __typename?: 'Contact' } & Pick<
                Types.Contact,
                'id' | 'name'
              >;
            }
        >;
      };
    };
};

export type ContactReferralFragment = { __typename?: 'Referral' } & Pick<
  Types.Referral,
  'id' | 'createdAt'
> & {
    referredTo: { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>;
  };

export type UpdateContactReferralMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  attributes: Types.ContactUpdateInput;
}>;

export type UpdateContactReferralMutation = { __typename?: 'Mutation' } & {
  updateContact?: Types.Maybe<
    { __typename?: 'ContactUpdateMutationPayload' } & {
      contact: { __typename?: 'Contact' } & Pick<Types.Contact, 'id'>;
    }
  >;
};

export const ContactReferralFragmentDoc = gql`
  fragment ContactReferral on Referral {
    id
    createdAt
    referredTo {
      id
      name
    }
  }
`;
export const ContactReferralTabDocument = gql`
  query ContactReferralTab($accountListId: ID!, $contactId: ID!) {
    contact(accountListId: $accountListId, id: $contactId) {
      id
      name
      contactReferralsByMe {
        nodes {
          ...ContactReferral
        }
      }
    }
  }
  ${ContactReferralFragmentDoc}
`;

/**
 * __useContactReferralTabQuery__
 *
 * To run a query within a React component, call `useContactReferralTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactReferralTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactReferralTabQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useContactReferralTabQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactReferralTabQuery,
    ContactReferralTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContactReferralTabQuery,
    ContactReferralTabQueryVariables
  >(ContactReferralTabDocument, options);
}
export function useContactReferralTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactReferralTabQuery,
    ContactReferralTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactReferralTabQuery,
    ContactReferralTabQueryVariables
  >(ContactReferralTabDocument, options);
}
export type ContactReferralTabQueryHookResult = ReturnType<
  typeof useContactReferralTabQuery
>;
export type ContactReferralTabLazyQueryHookResult = ReturnType<
  typeof useContactReferralTabLazyQuery
>;
export type ContactReferralTabQueryResult = Apollo.QueryResult<
  ContactReferralTabQuery,
  ContactReferralTabQueryVariables
>;
export const UpdateContactReferralDocument = gql`
  mutation UpdateContactReferral(
    $accountListId: ID!
    $attributes: ContactUpdateInput!
  ) {
    updateContact(
      input: { accountListId: $accountListId, attributes: $attributes }
    ) {
      contact {
        id
      }
    }
  }
`;
export type UpdateContactReferralMutationFn = Apollo.MutationFunction<
  UpdateContactReferralMutation,
  UpdateContactReferralMutationVariables
>;

/**
 * __useUpdateContactReferralMutation__
 *
 * To run a mutation, you first call `useUpdateContactReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactReferralMutation, { data, loading, error }] = useUpdateContactReferralMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateContactReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactReferralMutation,
    UpdateContactReferralMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactReferralMutation,
    UpdateContactReferralMutationVariables
  >(UpdateContactReferralDocument, options);
}
export type UpdateContactReferralMutationHookResult = ReturnType<
  typeof useUpdateContactReferralMutation
>;
export type UpdateContactReferralMutationResult =
  Apollo.MutationResult<UpdateContactReferralMutation>;
export type UpdateContactReferralMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactReferralMutation,
  UpdateContactReferralMutationVariables
>;
