/* eslint-disable */
import * as Types from '../../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTaskCommentMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  taskId: Types.Scalars['ID'];
  attributes: Types.TaskCommentCreateInput;
}>;

export type CreateTaskCommentMutation = { __typename?: 'Mutation' } & {
  createTaskComment?: Types.Maybe<
    { __typename?: 'TaskCommentCreateMutationPayload' } & {
      comment: { __typename?: 'Comment' } & Pick<
        Types.Comment,
        'id' | 'body' | 'createdAt' | 'me'
      > & {
          person: { __typename?: 'UserScopedToAccountList' } & Pick<
            Types.UserScopedToAccountList,
            'id' | 'firstName' | 'lastName'
          >;
        };
    }
  >;
};

export const CreateTaskCommentDocument = gql`
  mutation CreateTaskComment(
    $accountListId: ID!
    $taskId: ID!
    $attributes: TaskCommentCreateInput!
  ) {
    createTaskComment(
      input: {
        accountListId: $accountListId
        taskId: $taskId
        attributes: $attributes
      }
    ) {
      comment {
        id
        body
        createdAt
        me
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type CreateTaskCommentMutationFn = Apollo.MutationFunction<
  CreateTaskCommentMutation,
  CreateTaskCommentMutationVariables
>;

/**
 * __useCreateTaskCommentMutation__
 *
 * To run a mutation, you first call `useCreateTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskCommentMutation, { data, loading, error }] = useCreateTaskCommentMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      taskId: // value for 'taskId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateTaskCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskCommentMutation,
    CreateTaskCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTaskCommentMutation,
    CreateTaskCommentMutationVariables
  >(CreateTaskCommentDocument, options);
}
export type CreateTaskCommentMutationHookResult = ReturnType<
  typeof useCreateTaskCommentMutation
>;
export type CreateTaskCommentMutationResult =
  Apollo.MutationResult<CreateTaskCommentMutation>;
export type CreateTaskCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskCommentMutation,
  CreateTaskCommentMutationVariables
>;
