/* eslint-disable */
import * as Types from '../../../../../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateContactMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  attributes: Types.ContactCreateInput;
}>;

export type CreateContactMutation = { __typename?: 'Mutation' } & {
  createContact?: Types.Maybe<
    { __typename?: 'ContactCreateMutationPayload' } & {
      contact: { __typename?: 'Contact' } & Pick<Types.Contact, 'id'>;
    }
  >;
};

export const CreateContactDocument = gql`
  mutation CreateContact(
    $accountListId: ID!
    $attributes: ContactCreateInput!
  ) {
    createContact(
      input: { accountListId: $accountListId, attributes: $attributes }
    ) {
      contact {
        id
      }
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>;
export type CreateContactMutationResult =
  Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
