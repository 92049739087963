/* eslint-disable */
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import { TaskRowFragmentDoc } from '../../../Task/TaskRow/TaskRow.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContactTasksTabQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  tasksFilter?: Types.InputMaybe<Types.TaskFilterSetInput>;
}>;

export type ContactTasksTabQuery = { __typename?: 'Query' } & {
  tasks: { __typename?: 'TaskConnection' } & Pick<
    Types.TaskConnection,
    'totalCount'
  > & {
      nodes: Array<
        { __typename?: 'Task' } & Pick<
          Types.Task,
          | 'id'
          | 'activityType'
          | 'startAt'
          | 'completedAt'
          | 'result'
          | 'starred'
          | 'subject'
          | 'tagList'
        > & {
            comments: { __typename?: 'CommentConnection' } & Pick<
              Types.CommentConnection,
              'totalCount'
            >;
            contacts: { __typename?: 'ContactConnection' } & {
              nodes: Array<
                { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
              >;
            };
            user?: Types.Maybe<
              { __typename?: 'UserScopedToAccountList' } & Pick<
                Types.UserScopedToAccountList,
                'id' | 'firstName' | 'lastName'
              >
            >;
          }
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        Types.PageInfo,
        'endCursor' | 'hasNextPage'
      >;
    };
};

export const ContactTasksTabDocument = gql`
  query ContactTasksTab($accountListId: ID!, $tasksFilter: TaskFilterSetInput) {
    tasks(accountListId: $accountListId, tasksFilter: $tasksFilter) {
      nodes {
        ...TaskRow
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${TaskRowFragmentDoc}
`;

/**
 * __useContactTasksTabQuery__
 *
 * To run a query within a React component, call `useContactTasksTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactTasksTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactTasksTabQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      tasksFilter: // value for 'tasksFilter'
 *   },
 * });
 */
export function useContactTasksTabQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactTasksTabQuery,
    ContactTasksTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactTasksTabQuery, ContactTasksTabQueryVariables>(
    ContactTasksTabDocument,
    options,
  );
}
export function useContactTasksTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactTasksTabQuery,
    ContactTasksTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactTasksTabQuery,
    ContactTasksTabQueryVariables
  >(ContactTasksTabDocument, options);
}
export type ContactTasksTabQueryHookResult = ReturnType<
  typeof useContactTasksTabQuery
>;
export type ContactTasksTabLazyQueryHookResult = ReturnType<
  typeof useContactTasksTabLazyQuery
>;
export type ContactTasksTabQueryResult = Apollo.QueryResult<
  ContactTasksTabQuery,
  ContactTasksTabQueryVariables
>;
