/* eslint-disable */
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDataForTaskDrawerQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
}>;

export type GetDataForTaskDrawerQuery = { __typename?: 'Query' } & {
  accountList: { __typename?: 'AccountList' } & Pick<
    Types.AccountList,
    'id' | 'taskTagList'
  >;
  accountListUsers: { __typename?: 'AccountListUserConnection' } & {
    nodes: Array<
      { __typename?: 'AccountListUser' } & Pick<Types.AccountListUser, 'id'> & {
          user: { __typename?: 'UserScopedToAccountList' } & Pick<
            Types.UserScopedToAccountList,
            'id' | 'firstName' | 'lastName'
          >;
        }
    >;
  };
  contacts: { __typename?: 'ContactConnection' } & {
    nodes: Array<
      { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
    >;
  };
};

export type GetTaskModalContactsFilteredQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  contactsFilters?: Types.InputMaybe<Types.ContactFilterSetInput>;
}>;

export type GetTaskModalContactsFilteredQuery = { __typename?: 'Query' } & {
  contacts: { __typename?: 'ContactConnection' } & {
    nodes: Array<
      { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
    >;
  };
};

export type CreateTaskMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  attributes: Types.TaskCreateInput;
}>;

export type CreateTaskMutation = { __typename?: 'Mutation' } & {
  createTask?: Types.Maybe<
    { __typename?: 'TaskCreateMutationPayload' } & {
      task: { __typename?: 'Task' } & Pick<
        Types.Task,
        | 'id'
        | 'activityType'
        | 'subject'
        | 'startAt'
        | 'completedAt'
        | 'tagList'
        | 'notificationTimeBefore'
        | 'notificationType'
        | 'notificationTimeUnit'
      > & {
          contacts: { __typename?: 'ContactConnection' } & {
            nodes: Array<
              { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
            >;
          };
          user?: Types.Maybe<
            { __typename?: 'UserScopedToAccountList' } & Pick<
              Types.UserScopedToAccountList,
              'id' | 'firstName' | 'lastName'
            >
          >;
        };
    }
  >;
};

export type UpdateTaskMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  attributes: Types.TaskUpdateInput;
}>;

export type UpdateTaskMutation = { __typename?: 'Mutation' } & {
  updateTask?: Types.Maybe<
    { __typename?: 'TaskUpdateMutationPayload' } & {
      task: { __typename?: 'Task' } & Pick<
        Types.Task,
        | 'id'
        | 'activityType'
        | 'subject'
        | 'startAt'
        | 'completedAt'
        | 'tagList'
        | 'notificationTimeBefore'
        | 'notificationType'
        | 'notificationTimeUnit'
      > & {
          contacts: { __typename?: 'ContactConnection' } & {
            nodes: Array<
              { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
            >;
          };
          user?: Types.Maybe<
            { __typename?: 'UserScopedToAccountList' } & Pick<
              Types.UserScopedToAccountList,
              'id' | 'firstName' | 'lastName'
            >
          >;
        };
    }
  >;
};

export type DeleteTaskMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskMutation = { __typename?: 'Mutation' } & {
  deleteTask?: Types.Maybe<
    { __typename?: 'TaskDeleteMutationPayload' } & Pick<
      Types.TaskDeleteMutationPayload,
      'id'
    >
  >;
};

export type TaskMutationResponseFragment = { __typename?: 'Task' } & Pick<
  Types.Task,
  | 'id'
  | 'activityType'
  | 'subject'
  | 'startAt'
  | 'completedAt'
  | 'tagList'
  | 'notificationTimeBefore'
  | 'notificationType'
  | 'notificationTimeUnit'
> & {
    contacts: { __typename?: 'ContactConnection' } & {
      nodes: Array<
        { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
      >;
    };
    user?: Types.Maybe<
      { __typename?: 'UserScopedToAccountList' } & Pick<
        Types.UserScopedToAccountList,
        'id' | 'firstName' | 'lastName'
      >
    >;
  };

export const TaskMutationResponseFragmentDoc = gql`
  fragment TaskMutationResponse on Task {
    id
    activityType
    subject
    startAt
    completedAt
    tagList
    contacts {
      nodes {
        id
        name
      }
    }
    user {
      id
      firstName
      lastName
    }
    notificationTimeBefore
    notificationType
    notificationTimeUnit
  }
`;
export const GetDataForTaskDrawerDocument = gql`
  query GetDataForTaskDrawer($accountListId: ID!) {
    accountList(id: $accountListId) {
      id
      taskTagList
    }
    accountListUsers(accountListId: $accountListId) {
      nodes {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
    contacts(accountListId: $accountListId) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useGetDataForTaskDrawerQuery__
 *
 * To run a query within a React component, call `useGetDataForTaskDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForTaskDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForTaskDrawerQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *   },
 * });
 */
export function useGetDataForTaskDrawerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataForTaskDrawerQuery,
    GetDataForTaskDrawerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDataForTaskDrawerQuery,
    GetDataForTaskDrawerQueryVariables
  >(GetDataForTaskDrawerDocument, options);
}
export function useGetDataForTaskDrawerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForTaskDrawerQuery,
    GetDataForTaskDrawerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDataForTaskDrawerQuery,
    GetDataForTaskDrawerQueryVariables
  >(GetDataForTaskDrawerDocument, options);
}
export type GetDataForTaskDrawerQueryHookResult = ReturnType<
  typeof useGetDataForTaskDrawerQuery
>;
export type GetDataForTaskDrawerLazyQueryHookResult = ReturnType<
  typeof useGetDataForTaskDrawerLazyQuery
>;
export type GetDataForTaskDrawerQueryResult = Apollo.QueryResult<
  GetDataForTaskDrawerQuery,
  GetDataForTaskDrawerQueryVariables
>;
export const GetTaskModalContactsFilteredDocument = gql`
  query GetTaskModalContactsFiltered(
    $accountListId: ID!
    $contactsFilters: ContactFilterSetInput
  ) {
    contacts(accountListId: $accountListId, contactsFilter: $contactsFilters) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useGetTaskModalContactsFilteredQuery__
 *
 * To run a query within a React component, call `useGetTaskModalContactsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskModalContactsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskModalContactsFilteredQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      contactsFilters: // value for 'contactsFilters'
 *   },
 * });
 */
export function useGetTaskModalContactsFilteredQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskModalContactsFilteredQuery,
    GetTaskModalContactsFilteredQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTaskModalContactsFilteredQuery,
    GetTaskModalContactsFilteredQueryVariables
  >(GetTaskModalContactsFilteredDocument, options);
}
export function useGetTaskModalContactsFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskModalContactsFilteredQuery,
    GetTaskModalContactsFilteredQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTaskModalContactsFilteredQuery,
    GetTaskModalContactsFilteredQueryVariables
  >(GetTaskModalContactsFilteredDocument, options);
}
export type GetTaskModalContactsFilteredQueryHookResult = ReturnType<
  typeof useGetTaskModalContactsFilteredQuery
>;
export type GetTaskModalContactsFilteredLazyQueryHookResult = ReturnType<
  typeof useGetTaskModalContactsFilteredLazyQuery
>;
export type GetTaskModalContactsFilteredQueryResult = Apollo.QueryResult<
  GetTaskModalContactsFilteredQuery,
  GetTaskModalContactsFilteredQueryVariables
>;
export const CreateTaskDocument = gql`
  mutation CreateTask($accountListId: ID!, $attributes: TaskCreateInput!) {
    createTask(
      input: { accountListId: $accountListId, attributes: $attributes }
    ) {
      task {
        ...TaskMutationResponse
      }
    }
  }
  ${TaskMutationResponseFragmentDoc}
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options,
  );
}
export type CreateTaskMutationHookResult = ReturnType<
  typeof useCreateTaskMutation
>;
export type CreateTaskMutationResult =
  Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation UpdateTask($accountListId: ID!, $attributes: TaskUpdateInput!) {
    updateTask(
      input: { accountListId: $accountListId, attributes: $attributes }
    ) {
      task {
        ...TaskMutationResponse
      }
    }
  }
  ${TaskMutationResponseFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options,
  );
}
export type UpdateTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
  Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation DeleteTask($accountListId: ID!, $id: ID!) {
    deleteTask(input: { accountListId: $accountListId, id: $id }) {
      id
    }
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options,
  );
}
export type DeleteTaskMutationHookResult = ReturnType<
  typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
  Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
