/* eslint-disable */
import * as Types from '../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTasksForTaskListQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  activityType?: Types.InputMaybe<
    Array<Types.ActivityTypeEnum> | Types.ActivityTypeEnum
  >;
  contactIds?: Types.InputMaybe<
    Array<Types.Scalars['ID']> | Types.Scalars['ID']
  >;
  userIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  tags?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  completed?: Types.InputMaybe<Types.Scalars['Boolean']>;
  wildcardSearch?: Types.InputMaybe<Types.Scalars['String']>;
  startAt?: Types.InputMaybe<Types.DateTimeRangeInput>;
}>;

export type GetTasksForTaskListQuery = { __typename?: 'Query' } & {
  tasks: { __typename?: 'TaskConnection' } & Pick<
    Types.TaskConnection,
    'totalCount'
  > & {
      nodes: Array<
        { __typename?: 'Task' } & Pick<
          Types.Task,
          | 'id'
          | 'activityType'
          | 'subject'
          | 'startAt'
          | 'completedAt'
          | 'tagList'
        > & {
            contacts: { __typename?: 'ContactConnection' } & {
              nodes: Array<
                { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
              >;
            };
            user?: Types.Maybe<
              { __typename?: 'UserScopedToAccountList' } & Pick<
                Types.UserScopedToAccountList,
                'id' | 'firstName' | 'lastName'
              >
            >;
          }
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        Types.PageInfo,
        'startCursor' | 'endCursor'
      >;
    };
};

export const GetTasksForTaskListDocument = gql`
  query GetTasksForTaskList(
    $accountListId: ID!
    $first: Int
    $before: String
    $after: String
    $activityType: [ActivityTypeEnum!]
    $contactIds: [ID!]
    $userIds: [ID!]
    $tags: [String!]
    $completed: Boolean
    $wildcardSearch: String
    $startAt: DateTimeRangeInput
  ) {
    tasks(
      accountListId: $accountListId
      first: $first
      before: $before
      after: $after
      tasksFilter: {
        activityType: $activityType
        contactIds: $contactIds
        userIds: $userIds
        tags: $tags
        completed: $completed
        wildcardSearch: $wildcardSearch
        startAt: $startAt
      }
    ) {
      nodes {
        id
        activityType
        subject
        startAt
        completedAt
        tagList
        contacts {
          nodes {
            id
            name
          }
        }
        user {
          id
          firstName
          lastName
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;

/**
 * __useGetTasksForTaskListQuery__
 *
 * To run a query within a React component, call `useGetTasksForTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksForTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksForTaskListQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      activityType: // value for 'activityType'
 *      contactIds: // value for 'contactIds'
 *      userIds: // value for 'userIds'
 *      tags: // value for 'tags'
 *      completed: // value for 'completed'
 *      wildcardSearch: // value for 'wildcardSearch'
 *      startAt: // value for 'startAt'
 *   },
 * });
 */
export function useGetTasksForTaskListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTasksForTaskListQuery,
    GetTasksForTaskListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTasksForTaskListQuery,
    GetTasksForTaskListQueryVariables
  >(GetTasksForTaskListDocument, options);
}
export function useGetTasksForTaskListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTasksForTaskListQuery,
    GetTasksForTaskListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTasksForTaskListQuery,
    GetTasksForTaskListQueryVariables
  >(GetTasksForTaskListDocument, options);
}
export type GetTasksForTaskListQueryHookResult = ReturnType<
  typeof useGetTasksForTaskListQuery
>;
export type GetTasksForTaskListLazyQueryHookResult = ReturnType<
  typeof useGetTasksForTaskListLazyQuery
>;
export type GetTasksForTaskListQueryResult = Apollo.QueryResult<
  GetTasksForTaskListQuery,
  GetTasksForTaskListQueryVariables
>;
