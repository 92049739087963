/* eslint-disable */
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCommentsForTaskModalCommentListQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  taskId: Types.Scalars['ID'];
}>;

export type GetCommentsForTaskModalCommentListQuery = {
  __typename?: 'Query';
} & {
  task: { __typename?: 'Task' } & Pick<Types.Task, 'id'> & {
      comments: { __typename?: 'CommentConnection' } & {
        nodes: Array<
          { __typename?: 'Comment' } & Pick<
            Types.Comment,
            'id' | 'body' | 'createdAt' | 'me'
          > & {
              person: { __typename?: 'UserScopedToAccountList' } & Pick<
                Types.UserScopedToAccountList,
                'id' | 'firstName' | 'lastName'
              >;
            }
        >;
      };
    };
};

export const GetCommentsForTaskModalCommentListDocument = gql`
  query GetCommentsForTaskModalCommentList($accountListId: ID!, $taskId: ID!) {
    task(accountListId: $accountListId, id: $taskId) {
      id
      comments {
        nodes {
          id
          body
          createdAt
          me
          person {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetCommentsForTaskModalCommentListQuery__
 *
 * To run a query within a React component, call `useGetCommentsForTaskModalCommentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsForTaskModalCommentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsForTaskModalCommentListQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetCommentsForTaskModalCommentListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommentsForTaskModalCommentListQuery,
    GetCommentsForTaskModalCommentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommentsForTaskModalCommentListQuery,
    GetCommentsForTaskModalCommentListQueryVariables
  >(GetCommentsForTaskModalCommentListDocument, options);
}
export function useGetCommentsForTaskModalCommentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentsForTaskModalCommentListQuery,
    GetCommentsForTaskModalCommentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommentsForTaskModalCommentListQuery,
    GetCommentsForTaskModalCommentListQueryVariables
  >(GetCommentsForTaskModalCommentListDocument, options);
}
export type GetCommentsForTaskModalCommentListQueryHookResult = ReturnType<
  typeof useGetCommentsForTaskModalCommentListQuery
>;
export type GetCommentsForTaskModalCommentListLazyQueryHookResult = ReturnType<
  typeof useGetCommentsForTaskModalCommentListLazyQuery
>;
export type GetCommentsForTaskModalCommentListQueryResult = Apollo.QueryResult<
  GetCommentsForTaskModalCommentListQuery,
  GetCommentsForTaskModalCommentListQueryVariables
>;
