/* eslint-disable */
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteTaskMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  attributes: Types.TaskUpdateInput;
}>;

export type CompleteTaskMutation = { __typename?: 'Mutation' } & {
  updateTask?: Types.Maybe<
    { __typename?: 'TaskUpdateMutationPayload' } & {
      task: { __typename?: 'Task' } & Pick<
        Types.Task,
        'id' | 'result' | 'nextAction' | 'tagList' | 'completedAt'
      >;
    }
  >;
};

export const CompleteTaskDocument = gql`
  mutation CompleteTask($accountListId: ID!, $attributes: TaskUpdateInput!) {
    updateTask(
      input: { accountListId: $accountListId, attributes: $attributes }
    ) {
      task {
        id
        result
        nextAction
        tagList
        completedAt
      }
    }
  }
`;
export type CompleteTaskMutationFn = Apollo.MutationFunction<
  CompleteTaskMutation,
  CompleteTaskMutationVariables
>;

/**
 * __useCompleteTaskMutation__
 *
 * To run a mutation, you first call `useCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTaskMutation, { data, loading, error }] = useCompleteTaskMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCompleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteTaskMutation,
    CompleteTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteTaskMutation,
    CompleteTaskMutationVariables
  >(CompleteTaskDocument, options);
}
export type CompleteTaskMutationHookResult = ReturnType<
  typeof useCompleteTaskMutation
>;
export type CompleteTaskMutationResult =
  Apollo.MutationResult<CompleteTaskMutation>;
export type CompleteTaskMutationOptions = Apollo.BaseMutationOptions<
  CompleteTaskMutation,
  CompleteTaskMutationVariables
>;
