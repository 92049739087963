/* eslint-disable */
import * as Types from '../../../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetToolNotificationsQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
}>;

export type GetToolNotificationsQuery = { __typename?: 'Query' } & {
  fixCommitmentInfo: { __typename?: 'ContactConnection' } & Pick<
    Types.ContactConnection,
    'totalCount'
  >;
  fixMailingAddresses: { __typename?: 'ContactConnection' } & Pick<
    Types.ContactConnection,
    'totalCount'
  >;
  fixSendNewsletter: { __typename?: 'ContactConnection' } & Pick<
    Types.ContactConnection,
    'totalCount'
  >;
  fixEmailAddresses: { __typename?: 'PersonConnection' } & Pick<
    Types.PersonConnection,
    'totalCount'
  >;
  fixPhoneNumbers: { __typename?: 'PersonConnection' } & Pick<
    Types.PersonConnection,
    'totalCount'
  >;
  mergeContacts: { __typename?: 'ContactDuplicateConnection' } & Pick<
    Types.ContactDuplicateConnection,
    'totalCount'
  >;
  mergePeople: { __typename?: 'PersonDuplicateConnection' } & Pick<
    Types.PersonDuplicateConnection,
    'totalCount'
  >;
};

export const GetToolNotificationsDocument = gql`
  query GetToolNotifications($accountListId: ID!) {
    fixCommitmentInfo: contacts(
      accountListId: $accountListId
      contactsFilter: { statusValid: false }
    ) {
      totalCount
    }
    fixMailingAddresses: contacts(
      accountListId: $accountListId
      contactsFilter: { addressValid: false }
    ) {
      totalCount
    }
    fixSendNewsletter: contacts(
      accountListId: $accountListId
      contactsFilter: {
        newsletter: NO_VALUE
        status: [PARTNER_FINANCIAL, PARTNER_SPECIAL, PARTNER_PRAY]
      }
    ) {
      totalCount
    }
    fixEmailAddresses: people(
      accountListId: $accountListId
      peopleFilter: { emailAddressValid: false }
    ) {
      totalCount
    }
    fixPhoneNumbers: people(
      accountListId: $accountListId
      peopleFilter: { phoneNumberValid: false }
    ) {
      totalCount
    }
    mergeContacts: contactDuplicates(accountListId: $accountListId) {
      totalCount
    }
    mergePeople: personDuplicates(accountListId: $accountListId) {
      totalCount
    }
  }
`;

/**
 * __useGetToolNotificationsQuery__
 *
 * To run a query within a React component, call `useGetToolNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolNotificationsQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *   },
 * });
 */
export function useGetToolNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetToolNotificationsQuery,
    GetToolNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetToolNotificationsQuery,
    GetToolNotificationsQueryVariables
  >(GetToolNotificationsDocument, options);
}
export function useGetToolNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetToolNotificationsQuery,
    GetToolNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetToolNotificationsQuery,
    GetToolNotificationsQueryVariables
  >(GetToolNotificationsDocument, options);
}
export type GetToolNotificationsQueryHookResult = ReturnType<
  typeof useGetToolNotificationsQuery
>;
export type GetToolNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetToolNotificationsLazyQuery
>;
export type GetToolNotificationsQueryResult = Apollo.QueryResult<
  GetToolNotificationsQuery,
  GetToolNotificationsQueryVariables
>;
