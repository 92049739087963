/* eslint-disable */
import * as Types from '../../../../../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDonationModalQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
}>;

export type GetDonationModalQuery = { __typename?: 'Query' } & {
  accountList: { __typename?: 'AccountList' } & Pick<
    Types.AccountList,
    'id' | 'currency'
  > & {
      appeals?: Types.Maybe<
        Array<
          { __typename?: 'Appeal' } & Pick<
            Types.Appeal,
            'active' | 'name' | 'id'
          >
        >
      >;
    };
  designationAccounts: Array<
    { __typename?: 'DesignationAccountsGroup' } & {
      designationAccounts: Array<
        { __typename?: 'DesignationAccountRest' } & Pick<
          Types.DesignationAccountRest,
          'id' | 'name' | 'active'
        >
      >;
    }
  >;
};

export type GetAccountListDonorAccountsQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  searchTerm: Types.Scalars['String'];
}>;

export type GetAccountListDonorAccountsQuery = { __typename?: 'Query' } & {
  accountListDonorAccounts?: Types.Maybe<
    Array<
      { __typename?: 'AccountListDonorAccount' } & Pick<
        Types.AccountListDonorAccount,
        'id' | 'accountNumber' | 'displayName'
      >
    >
  >;
};

export type AddDonationMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  attributes: Types.DonationCreateInput;
}>;

export type AddDonationMutation = { __typename?: 'Mutation' } & {
  createDonation?: Types.Maybe<
    { __typename?: 'DonationCreateMutationPayload' } & {
      donation: { __typename?: 'Donation' } & Pick<Types.Donation, 'id'>;
    }
  >;
};

export const GetDonationModalDocument = gql`
  query GetDonationModal($accountListId: ID!) {
    accountList(id: $accountListId) {
      id
      currency
      appeals {
        active
        name
        id
      }
    }
    designationAccounts(accountListId: $accountListId) {
      designationAccounts {
        id
        name
        active
      }
    }
  }
`;

/**
 * __useGetDonationModalQuery__
 *
 * To run a query within a React component, call `useGetDonationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationModalQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *   },
 * });
 */
export function useGetDonationModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonationModalQuery,
    GetDonationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDonationModalQuery, GetDonationModalQueryVariables>(
    GetDonationModalDocument,
    options,
  );
}
export function useGetDonationModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonationModalQuery,
    GetDonationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDonationModalQuery,
    GetDonationModalQueryVariables
  >(GetDonationModalDocument, options);
}
export type GetDonationModalQueryHookResult = ReturnType<
  typeof useGetDonationModalQuery
>;
export type GetDonationModalLazyQueryHookResult = ReturnType<
  typeof useGetDonationModalLazyQuery
>;
export type GetDonationModalQueryResult = Apollo.QueryResult<
  GetDonationModalQuery,
  GetDonationModalQueryVariables
>;
export const GetAccountListDonorAccountsDocument = gql`
  query GetAccountListDonorAccounts($accountListId: ID!, $searchTerm: String!) {
    accountListDonorAccounts(
      accountListId: $accountListId
      searchTerm: $searchTerm
    ) {
      id
      accountNumber
      displayName
    }
  }
`;

/**
 * __useGetAccountListDonorAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountListDonorAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountListDonorAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountListDonorAccountsQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetAccountListDonorAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountListDonorAccountsQuery,
    GetAccountListDonorAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountListDonorAccountsQuery,
    GetAccountListDonorAccountsQueryVariables
  >(GetAccountListDonorAccountsDocument, options);
}
export function useGetAccountListDonorAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountListDonorAccountsQuery,
    GetAccountListDonorAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountListDonorAccountsQuery,
    GetAccountListDonorAccountsQueryVariables
  >(GetAccountListDonorAccountsDocument, options);
}
export type GetAccountListDonorAccountsQueryHookResult = ReturnType<
  typeof useGetAccountListDonorAccountsQuery
>;
export type GetAccountListDonorAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAccountListDonorAccountsLazyQuery
>;
export type GetAccountListDonorAccountsQueryResult = Apollo.QueryResult<
  GetAccountListDonorAccountsQuery,
  GetAccountListDonorAccountsQueryVariables
>;
export const AddDonationDocument = gql`
  mutation AddDonation($accountListId: ID!, $attributes: DonationCreateInput!) {
    createDonation(
      input: { accountListId: $accountListId, attributes: $attributes }
    ) {
      donation {
        id
      }
    }
  }
`;
export type AddDonationMutationFn = Apollo.MutationFunction<
  AddDonationMutation,
  AddDonationMutationVariables
>;

/**
 * __useAddDonationMutation__
 *
 * To run a mutation, you first call `useAddDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDonationMutation, { data, loading, error }] = useAddDonationMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAddDonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDonationMutation,
    AddDonationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDonationMutation, AddDonationMutationVariables>(
    AddDonationDocument,
    options,
  );
}
export type AddDonationMutationHookResult = ReturnType<
  typeof useAddDonationMutation
>;
export type AddDonationMutationResult =
  Apollo.MutationResult<AddDonationMutation>;
export type AddDonationMutationOptions = Apollo.BaseMutationOptions<
  AddDonationMutation,
  AddDonationMutationVariables
>;
