/* eslint-disable */
import * as Types from '../../../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSearchMenuContactsQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  contactsFilter?: Types.InputMaybe<Types.ContactFilterSetInput>;
}>;

export type GetSearchMenuContactsQuery = { __typename?: 'Query' } & {
  contacts: { __typename?: 'ContactConnection' } & Pick<
    Types.ContactConnection,
    'totalCount'
  > & {
      nodes: Array<
        { __typename?: 'Contact' } & Pick<
          Types.Contact,
          'id' | 'name' | 'status'
        >
      >;
    };
};

export const GetSearchMenuContactsDocument = gql`
  query GetSearchMenuContacts(
    $accountListId: ID!
    $contactsFilter: ContactFilterSetInput
  ) {
    contacts(
      accountListId: $accountListId
      contactsFilter: $contactsFilter
      first: 5
    ) {
      nodes {
        id
        name
        status
      }
      totalCount
    }
  }
`;

/**
 * __useGetSearchMenuContactsQuery__
 *
 * To run a query within a React component, call `useGetSearchMenuContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchMenuContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchMenuContactsQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      contactsFilter: // value for 'contactsFilter'
 *   },
 * });
 */
export function useGetSearchMenuContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchMenuContactsQuery,
    GetSearchMenuContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchMenuContactsQuery,
    GetSearchMenuContactsQueryVariables
  >(GetSearchMenuContactsDocument, options);
}
export function useGetSearchMenuContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchMenuContactsQuery,
    GetSearchMenuContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchMenuContactsQuery,
    GetSearchMenuContactsQueryVariables
  >(GetSearchMenuContactsDocument, options);
}
export type GetSearchMenuContactsQueryHookResult = ReturnType<
  typeof useGetSearchMenuContactsQuery
>;
export type GetSearchMenuContactsLazyQueryHookResult = ReturnType<
  typeof useGetSearchMenuContactsLazyQuery
>;
export type GetSearchMenuContactsQueryResult = Apollo.QueryResult<
  GetSearchMenuContactsQuery,
  GetSearchMenuContactsQueryVariables
>;
