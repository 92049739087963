/* eslint-disable */
import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadConstantsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LoadConstantsQuery = { __typename?: 'Query' } & {
  constant: { __typename?: 'Constant' } & {
    activities?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    languages?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    likelyToGiveOptions?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    locations?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id'>>
    >;
    pledgeCurrencies?: Types.Maybe<
      Array<
        { __typename?: 'IdKeyValue' } & Pick<Types.IdKeyValue, 'id' | 'value'>
      >
    >;
    pledgeFrequencies?: Types.Maybe<
      Array<
        { __typename?: 'IdKeyValue' } & Pick<
          Types.IdKeyValue,
          'id' | 'key' | 'value'
        >
      >
    >;
    pledgesReceived?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    preferredContactMethods?: Types.Maybe<
      Array<
        { __typename?: 'IdKeyValue' } & Pick<Types.IdKeyValue, 'id' | 'value'>
      >
    >;
    sendAppeals?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    sendNewsletterOptions?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    statuses?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    times?: Types.Maybe<
      Array<{ __typename?: 'Time' } & Pick<Types.Time, 'key' | 'value'>>
    >;
  };
};

export const LoadConstantsDocument = gql`
  query LoadConstants {
    constant {
      activities {
        id
        value
      }
      languages {
        id
        value
      }
      likelyToGiveOptions {
        id
        value
      }
      locations {
        id
      }
      pledgeCurrencies {
        id
        value
      }
      pledgeFrequencies {
        id
        key
        value
      }
      pledgesReceived {
        id
        value
      }
      preferredContactMethods {
        id
        value
      }
      sendAppeals {
        id
        value
      }
      sendNewsletterOptions {
        id
        value
      }
      statuses {
        id
        value
      }
      times {
        key
        value
      }
    }
  }
`;

/**
 * __useLoadConstantsQuery__
 *
 * To run a query within a React component, call `useLoadConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadConstantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadConstantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadConstantsQuery,
    LoadConstantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadConstantsQuery, LoadConstantsQueryVariables>(
    LoadConstantsDocument,
    options,
  );
}
export function useLoadConstantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadConstantsQuery,
    LoadConstantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadConstantsQuery, LoadConstantsQueryVariables>(
    LoadConstantsDocument,
    options,
  );
}
export type LoadConstantsQueryHookResult = ReturnType<
  typeof useLoadConstantsQuery
>;
export type LoadConstantsLazyQueryHookResult = ReturnType<
  typeof useLoadConstantsLazyQuery
>;
export type LoadConstantsQueryResult = Apollo.QueryResult<
  LoadConstantsQuery,
  LoadConstantsQueryVariables
>;
