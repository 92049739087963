/* eslint-disable */
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTopBarQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetTopBarQuery = { __typename?: 'Query' } & {
  accountLists: { __typename?: 'AccountListConnection' } & {
    nodes: Array<
      { __typename?: 'AccountList' } & Pick<Types.AccountList, 'id' | 'name'>
    >;
  };
  user: { __typename?: 'User' } & Pick<
    Types.User,
    'id' | 'firstName' | 'lastName' | 'admin' | 'developer'
  > & {
      keyAccounts: Array<
        { __typename?: 'KeyAccount' } & Pick<Types.KeyAccount, 'id' | 'email'>
      >;
      administrativeOrganizations: { __typename?: 'OrganizationConnection' } & {
        nodes: Array<
          { __typename?: 'Organization' } & Pick<Types.Organization, 'id'>
        >;
      };
    };
};

export const GetTopBarDocument = gql`
  query GetTopBar {
    accountLists {
      nodes {
        id
        name
      }
    }
    user {
      id
      firstName
      lastName
      admin
      developer
      keyAccounts {
        id
        email
      }
      administrativeOrganizations {
        nodes {
          id
        }
      }
    }
  }
`;

/**
 * __useGetTopBarQuery__
 *
 * To run a query within a React component, call `useGetTopBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopBarQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopBarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTopBarQuery,
    GetTopBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTopBarQuery, GetTopBarQueryVariables>(
    GetTopBarDocument,
    options,
  );
}
export function useGetTopBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopBarQuery,
    GetTopBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTopBarQuery, GetTopBarQueryVariables>(
    GetTopBarDocument,
    options,
  );
}
export type GetTopBarQueryHookResult = ReturnType<typeof useGetTopBarQuery>;
export type GetTopBarLazyQueryHookResult = ReturnType<
  typeof useGetTopBarLazyQuery
>;
export type GetTopBarQueryResult = Apollo.QueryResult<
  GetTopBarQuery,
  GetTopBarQueryVariables
>;
