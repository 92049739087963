/* eslint-disable */
import * as Types from '../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTaskForTaskDrawerQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID'];
  taskId: Types.Scalars['ID'];
}>;

export type GetTaskForTaskDrawerQuery = { __typename?: 'Query' } & {
  task: { __typename?: 'Task' } & Pick<
    Types.Task,
    | 'id'
    | 'activityType'
    | 'subject'
    | 'startAt'
    | 'completedAt'
    | 'tagList'
    | 'notificationTimeBefore'
    | 'notificationType'
    | 'notificationTimeUnit'
  > & {
      contacts: { __typename?: 'ContactConnection' } & {
        nodes: Array<
          { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
        >;
      };
      user?: Types.Maybe<
        { __typename?: 'UserScopedToAccountList' } & Pick<
          Types.UserScopedToAccountList,
          'id' | 'firstName' | 'lastName'
        >
      >;
    };
};

export const GetTaskForTaskDrawerDocument = gql`
  query GetTaskForTaskDrawer($accountListId: ID!, $taskId: ID!) {
    task(accountListId: $accountListId, id: $taskId) {
      id
      activityType
      subject
      startAt
      completedAt
      tagList
      contacts {
        nodes {
          id
          name
        }
      }
      user {
        id
        firstName
        lastName
      }
      notificationTimeBefore
      notificationType
      notificationTimeUnit
    }
  }
`;

/**
 * __useGetTaskForTaskDrawerQuery__
 *
 * To run a query within a React component, call `useGetTaskForTaskDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskForTaskDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskForTaskDrawerQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskForTaskDrawerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskForTaskDrawerQuery,
    GetTaskForTaskDrawerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTaskForTaskDrawerQuery,
    GetTaskForTaskDrawerQueryVariables
  >(GetTaskForTaskDrawerDocument, options);
}
export function useGetTaskForTaskDrawerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskForTaskDrawerQuery,
    GetTaskForTaskDrawerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTaskForTaskDrawerQuery,
    GetTaskForTaskDrawerQueryVariables
  >(GetTaskForTaskDrawerDocument, options);
}
export type GetTaskForTaskDrawerQueryHookResult = ReturnType<
  typeof useGetTaskForTaskDrawerQuery
>;
export type GetTaskForTaskDrawerLazyQueryHookResult = ReturnType<
  typeof useGetTaskForTaskDrawerLazyQuery
>;
export type GetTaskForTaskDrawerQueryResult = Apollo.QueryResult<
  GetTaskForTaskDrawerQuery,
  GetTaskForTaskDrawerQueryVariables
>;
